<template>
    <main v-if="user" class="px-4">
        <section class="user-info text-center my-4">
            <div class="avatar">
                <img v-if="user.avatar" :src="user.avatar | formatImage(500, 500)" alt="">
            </div>
            <h2 class="user-name">
                {{ user.firstName }} {{ user.lastName }}
            </h2>
            <div v-if="user.clientGroups && user.clientGroups.length > 0" class="user-group">
                {{ user.clientGroups[0].name }}
            </div>
            <div class="user-location">
                {{ shortAddress }}
            </div>
            <div v-if="user.description" class="user-description">
                {{ user.description }}
            </div>
        </section>

        <posts-list
            unique-id="user-details-post-list"
            :user-id="user.id"
            hide-search-description
        />

    </main>
</template>
<script lang="ts">
import { User, CurrentUser } from '@/types';
import Vue from '@/vueTyped';
import { shortAddressFromComponents } from '@/util.location';
import PostsList from '@/components/PostsList.vue';

export default Vue.extend({
    name: 'UserDetail',
    metaInfo: {
        title: 'Profile',
        meta: [{
            name: 'robots',
            content: 'noindex',
        }],
    },
    components: {
        PostsList,
    },
    props: {
        userId: {
            required: true,
            type: String,
        },
    },
    data() {
        return {
            user: null as User | CurrentUser | null,
        };
    },
    computed: {
        // isCurrentUser(): boolean {
        //     const { currentUser } = this.$store.state.account;
        //     return currentUser !== null && this.user?.id === currentUser.id;
        // },
        shortAddress(): string {
            if (!this.user) {
                return '';
            }
            if (!(this.user as CurrentUser).addressComponents) {
                return this.user.shortAddress;
            }
            return shortAddressFromComponents((this.user as CurrentUser).addressComponents);
        }
    },
    watch: {
        userId: {
            immediate: true,
            async handler(this: any, userId) {
                const { data } = await this.$store.dispatch('fetchUser', { id: userId });
                this.user = data;
            }
        },
    },
});
</script>
<style lang="postcss" scoped>
.avatar {
    display: inline-block;
}
.avatar img {
    width: 8rem;
    height: 8rem;
    border-radius: 50%;
    object-fit: cover;
    object-position: top;
}
.user-group {
    color: var(--color-dark);
    margin-top: -0.5em;
    margin-bottom: 2em;
}
.user-location {
    color: var(--color-dark);
}
</style>

